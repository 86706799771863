import React, { useEffect } from 'react';

function AppStore() {
    useEffect(() => {
        window.location.href = "https://apps.apple.com/us/app/birthpay/id1575770355";
    }, []);

    return null;
}

export default AppStore;