import React from "react";
import { Link } from "react-router-dom";
import styles from "../styles/Footer.module.css";
import AppStore from "../assets/app.svg";

function Footer() {
  return (
    <div className={styles.footerList}>
      <a href="itms-apps://apps.apple.com/us/app/birthpay/id1575770355">
        <img src={AppStore} alt="app store" className="appImg" />
      </a>
      <div className={styles.linkList}>
        <Link className={styles.link} to="/contact">
          Contact
        </Link>
        <Link className={styles.link} to="/privacy">
          Privacy
        </Link>
        <Link className={styles.link} to="/terms">
          Terms
        </Link>
      </div>
      <p>Copyright &copy; 2023 birthpay</p>
    </div>
  );
}

export default Footer;
