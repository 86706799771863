import React from "react";
import "../styles/App.css";

const Privacy = () => {
  return (
    // <div className="container">
    //   <h1>Privacy Policy</h1>
    //   <p>
    //     Last updated <strong> August 17, 2023 </strong>
    //   </p>
    //   <ul className="list">
    //     <li>We don't sell or share your data.</li>
    // <li>
    //   To delete your data, click the "Delete Account" button at the base of
    //   the "Settings" page.
    // </li>
    //   </ul>
    // </div>
    <div class="container">
      <h1 class="brand">Privacy Policy</h1>
      <p>
        Last updated <strong> October 31, 2023 </strong>
      </p>
      <p class="sub">
        This policy details how data about you is used when you access our
        websites and services (together, "birthpay") or interact with us. If we
        update it, we will revise the date, place notices on birthpay if changes
        are material, and/or obtain your consent as required by law.
      </p>
      <h3>1. Protecting your privacy</h3>
      <ul className="list">
        <li>
          We take precautions to prevent unauthorized access to or misuse of
          data about you.
        </li>
        <li>We do not run ads.</li>
        <li>
          We do not share your data with third parties for marketing purposes.
        </li>
        <li>We do not engage in cross-marketing or link-referral programs.</li>
        <li>We do not employ tracking devices for marketing purposes.</li>
        <li>
          We do not send you unsolicited communications for marketing purposes.
        </li>
        <li>
          We do not engage in affiliate marketing (and prohibit it on birthpay).
        </li>
        <li>
          We do provide email proxy &amp; relay services to reduce unwanted
          email.
        </li>

        <h3>
          2. Data we use to provide/improve our services and/or combat
          fraud/abuse:
        </h3>

        <li>
          data you post on or send via birthpay, and/or send us directly or via
          other sites
        </li>
        <li>data you submit or provide (e.g. name, email, phone)</li>
        <li>
          web log data (e.g. web pages viewed, access times, IP address, HTTP
          headers).
        </li>
        <li>
          data collected via cookies (e.g. search data and "favorites" lists).
        </li>
        <li>
          data about your device(s) (e.g. screen size, DOM local storage,
          plugins).
        </li>
        <li>
          data from 3rd parties (e.g. phone type, geo-location via IP address).
        </li>

        <h3>3. Data we store</h3>

        <li>
          We retain data as needed for our business purposes and/or as required
          by law.
        </li>
        <li>
          We make good faith efforts to store data securely, but can make no
          guarantees.
        </li>
        <li>
          You may access and update certain data about you by emailing us at{" "}
          <a class="hoverText" href="mailto:contact@birthpay.xyz">
            contact@birthpay.xyz
          </a>
        </li>

        <h3>4. Circumstances in which we may disclose user data:</h3>

        <li>
          to respond to subpoenas, search warrants, court orders, or other legal
          process.
        </li>
        <li>
          to protect our rights, property, or safety, or that of users of
          birthpay or the general public.
        </li>
        <li>
          with your consent (e.g. if you authorize us to share data with other
          users).
        </li>
        <li>
          in connection with a merger, bankruptcy, or sale/transfer of assets.
        </li>
        <li>
          in aggregate/summary form, where it cannot reasonably be used to
          identify you.
        </li>

        <h3>5. Data deletion:</h3>

        <li>
          To delete your data, click the "Delete Account" button at the base of
          the "Settings" page.
        </li>
      </ul>
    </div>
  );
};

export default Privacy;
