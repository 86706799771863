import React from "react";
import "../styles/App.css";

const Contact = () => {
  return (
    <div className="container">
      <h1>Contact Us</h1>
      <p className="description">
        {" "}
        Questions or comments, send us an email at
        <a href="mailto:contact@birthpay.xyz"> contact@birthpay.xyz</a>
      </p>
    </div>
  );
};

export default Contact;
