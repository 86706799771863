import React from "react";
import "../styles/App.css";

const HomePage = () => {
  return (
    <div class="container">
      <h1>Welcome to birthpay</h1>
      <p>
        Never forget a birthday. Never forget a present.
      </p>
    </div>
  );
};

export default HomePage;
