import React from "react";
import { Link } from "react-router-dom";
import styles from "../styles/NavBar.module.css";
import PinkCake from "../assets/PinkCake.png";

function NavBar() {
  return (
    <nav className={styles.navBar}>
      <Link to="/">
        <a href="/">
          <img src={PinkCake} alt="birthpay logo" className="appLogo" />
        </a>
      </Link>
    </nav>
  );
}

export default NavBar;
